<template>
  <div class="mb-4">
    
      <CRow>
      
        <CCol lg="3">
         <div class="form-group">									
          <CInput  v-model="yearCovered" placeholder="Year Covered" type="Number"/> 
								</div> 
        </CCol>       
        <CCol lg="2">
          <CInput  v-model="created_by" placeholder="Processed By"/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="type" 
            :options="[
            	{
            		type: '1st Half',
            		id: '1'
            	},
            	{
            		type: '2nd Half',
            		id: '2'
            	},
            	
            ]"
            :reduce="item => item.id"
            v-model="type"
            placeholder="Type"
          > 
          </v-select>  
        </CCol>
      
      

        <CCol lg="3" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import style from '../../style.css';
  import vSelect from 'vue-select'
  import Datepicker from 'vuejs-datepicker';
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "AdjustmentSearch",
        data() {
          return {
            created_by: "",            
            yearCovered:"",                        
            type : "",
           
          	userList: {
				    data: []
			      },
          }
        },
      mounted() {
        
        this.getUser();
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
            created_by: this.created_by,           
            yearCovered: this.yearCovered,            
            type : this.type,
          }
          this.$emit("adjustment-search-query", data)
        },
        clearSearch() {            
            this.created_by = "";          
            this.yearCovered = "";            
            this.type = "";                                  
          const data = {
            created_by: this.created_by,           
            yearCovered: this.yearCovered,            
            type : this.type,
          }
          this.$emit("adjustment-search-query", data)
        },

       
        getUser(){

	    	axios.get(config.api_path+'/thirteenth-month/list')
	    	.then(response => {
	    		this.userList = response.data;	    	
	    	})

	    },


	  
      }
    }
</script>

<style scoped>

</style>
